import React, { useState } from 'react';
import './footer-desk.scss';

import androidSvg from '../../../../static/img/icon_android.svg';
import iosSvg from '../../../../static/img/icon_ios.svg';

import chromeIconSvg from '../../../../static/img/Chrome.svg';
import firefoxIconSvg from '../../../../static/img/Firefox.svg';

const Copyright = () => {
    const dt = new Date();
    return (
        <div className="copyright">Copyright © 1996–{dt.getFullYear()} Seznam.cz, a. s.</div>
    );
};

const Footer = () => {
    return (
        <footer>
            <div className="footer-content">
                <h2>
                    Doplněk si můžete stáhnout do těchto prohlížečů
            </h2>
                <div className="download-list" data-dot="download-links">
                    <a className="link" href="?prohlizec=Firefox" data-dot="firefox">
                        <button>
                            <img alt="firefox" src={firefoxIconSvg} />
                            <div className="label">
                                Mozilla Firefox
                            </div>
                        </button>
                    </a>
                    <a className="link" href="?prohlizec=Chrome" data-dot="chrome">
                        <button>
                            <img alt="chrome" src={chromeIconSvg} />
                            <div className="label">
                                Google Chrome
                            </div>
                        </button>
                    </a>
                </div>
                <div className="footer-links">
                    <a href="https://napoveda.seznam.cz/cz/doplnky/" target="_blank">Nápověda&nbsp;</a>
                    <a href="https://napoveda.seznam.cz/cz/smluvni-podminky/podminky-seznam-doplnky/" target="_blank">&nbsp;Smluvní podmínky&nbsp;</a>
                </div>
                <Copyright />
            </div>
        </footer>
    );
};


class FooterDesk extends React.PureComponent {
    render() {
        return (
            <Footer />
        );
    }
}

export default FooterDesk;
