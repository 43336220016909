import React from 'react';

import './menu-desk.scss';

import logo from '../../../../static/img/seznam_logo.svg';

const MenuDesk = (props) => {
  return (
    <menu>
      <div className="logo">
        <a href="https://www.seznam.cz/"><img alt="Seznam.cz" src={logo} /></a>
      </div>
      <ul className="nav">
        <li><a href="/prohlizec">Prohlížeč Seznam.cz</a></li>
        <li><a href="https://www.seznam.cz/mobilni-aplikace">Aplikace</a></li>
        <li className="active" id="extension-link"><a className="active" href="/doplnky">Seznam doplňky</a></li>
      </ul>
      <div className="centerer" />
    </menu>
  );
};

export default MenuDesk;
