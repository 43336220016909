import React from "react"
import DownArrow from "../snippets/down-arrow"

import chromeIconSvg from "../../../../static/img/Chrome.svg"
import firefoxIconSvg from "../../../../static/img/Firefox.svg"
import slogo from "../../../../static/img/slogo.svg"

// import bgSvg from '../../../../static/img/Header_Image_Universal.svg';
import bgSvg from "../../../../static/img/header_oldschool.svg"

import "./header-desk.scss"

const Copyright = () => {
    const dt = new Date()
    return (
        <div className="copyright">
            Copyright © 1996–{dt.getFullYear()} Seznam.cz, a. s.
        </div>
    )
}

const InaccessibleExtHeader = props => {
    return (
        <header className="inaccessible">
            <div className="header-wrapper">
                <div className="content-wrapper">
                    <div className="header-content">
                        <img className="logo" src={slogo} />
                        <h1>
                            Doplňky od Seznamu jsou dostupné
                            <br /> pro tyto prohlížeče
                        </h1>
                        <div
                            className="download-list"
                            data-dot="download-links"
                        >
                            <a
                                className="link"
                                href="/doplnky/?prohlizec=firefox"
                                data-dot="firefox"
                            >
                                <button>
                                    <img alt="firefox" src={firefoxIconSvg} />
                                    <div className="label">Mozilla Firefox</div>
                                </button>
                            </a>
                            <a
                                className="link"
                                href="/doplnky/?prohlizec=chrome"
                                data-dot="chrome"
                            >
                                <button>
                                    <img alt="chrome" src={chromeIconSvg} />
                                    <div className="label">Google Chrome</div>
                                </button>
                            </a>
                        </div>
                        <p className="desc">
                            Nebo vyzkoušejte Seznam prohlížeč. Umí vše co
                            doplněk a<br /> mnohem více, je jednoduchý na
                            ovládání, a přesto tak chytrý.
                        </p>
                        <a href="/prohlizec" className="button-more">
                            <button type="button">
                                Více o Seznam prohlížeči
                            </button>
                        </a>
                    </div>
                </div>
                <div className="footer-links">
                    <a href="https://napoveda.seznam.cz/cz/doplnky/" target="_blank">Nápověda&nbsp;</a>
                    <a href="https://napoveda.seznam.cz/cz/smluvni-podminky/podminky-seznam-doplnky/" target="_blank">&nbsp;Smluvní podmínky&nbsp;</a>
                </div>
                <Copyright />
            </div>
        </header>
    )
}

const Header = props => {
    return (
        <header>
            <div className="header-wrapper">
                <div className="content-wrapper">
                    <div className="header-content">
                        <h1>Doplňky od Seznamu</h1>
                        <p className="desc">
                            Zkrátí vám cestu na Seznam, přeloží slova, která
                            neznáte,
                            <br /> převede měny a upozorní, když přijde nový
                            e-mail.
                        </p>
                        <img className="banner" src={bgSvg} alt="" />
                    </div>
                </div>
            </div>
            <DownArrow />
        </header>
    )
}

class HeaderDesk extends React.PureComponent {
    render() {
        const browserHasExtensions = this.props.browserHasExtensions
        if (browserHasExtensions) {
            return <Header browser={this.props.browser} />
        } else {
            return <InaccessibleExtHeader />
        }
    }
}

export default HeaderDesk
