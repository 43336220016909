import React from "react"
import { Location } from "@reach/router"
import queryString from "query-string"
import { checkExtensionStat } from "../../../helpers/detectExtensions"

import "./add-button.scss"

const Button = ({ os, text, search, link }) => {
    return (
        <a href={link} className="add-button" data-dot="download-button-bar" target='_blank'>
            <button type="button" data-dot={os}>
                {text ? (
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                ) : (
                    <div>Přidat</div>
                )}
            </button>
        </a>
    )
}

class AddButton extends React.PureComponent {
    render() {
        return (
            <Location>
                {({ location, navigate }) => (
                    <Button
                        {...this.props}
                        location={location}
                        navigate={navigate}
                        search={
                            location.search
                                ? queryString.parse(location.search)
                                : null
                        }
                    />
                )}
            </Location>
        )
    }
}

export default AddButton
