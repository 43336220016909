import React from 'react';
import AddButton from '../snippets/add-button'

import './sections-desk.scss';


const Section = ({ sectionContent, extensionList, browser }) => {
    const showButton = extensionList?.includes(sectionContent.extensionName);
    const link = !browser || (browser === 'Chrome' ? sectionContent.linkChrome : null || browser === 'Firefox' ? sectionContent.linkFirefox : null)

    return (
        <section className="full feature">
            <div className={`content-wrapper ${sectionContent.reverseContent ? 'reverse' : null}`}>
                <div className="animation">
                    <img src={sectionContent.animation} alt="" />
                </div>
                <div className="section-content">
                    <h2>{sectionContent.h}</h2>
                    <p>{sectionContent.p}</p>
                    {!showButton && <AddButton {...sectionContent} link={link} />}
                    {showButton && <p className="installed">Doplňek {sectionContent.extensionNameCZ} již máte přidán v Chromu.</p>}
                </div>
            </div>
        </section>
    );
};


export default Section;
