import React, { useEffect } from "react";

import MenuDesk from "../components/desktop/menu/menu-desk";
import HeaderDesk from "../components/desktop/header/header-desk";
import FooterDesk from "../components/desktop/footer/footer-desk";
import MainFeatureSectionDesk from "../components/desktop/sections/main-feature-section-desk";
import FeatureListSectionDesk from "../components/desktop/sections/feature-list-section-desk";

import detectOsSzn from "../helpers/detectOsSzn";
import detectOs from "../helpers/detectOs";
import sectionData from "../section-data";
import {
    extensionListener,
    checkExtensionStat,
} from "../helpers/detectExtensions";

const MainSection = (props) => {
    const components = {
        MainFeatureSection: MainFeatureSectionDesk,
        FeatureListSection: FeatureListSectionDesk,
    };
    return sectionData.map((data) => {
        const Template = components[data.sectionType];
        return (
            <Template
                key={data.id}
                sectionContent={data}
                extensionList={props.extensionList}
                browser={props.browser}
            />
        );
    });
};

class IndexPage extends React.Component {
    mainFeatureSections = sectionData.map((data) => (
        <MainFeatureSectionDesk key={data.id} sectionContent={data} />
    ));

    constructor(props) {
        super(props);
        this.state = { os: "null" };
    }

    componentDidMount() {
        const extensionList = [];
        const handleExtensionsChange = (data) => {
            const extension = data.data;
            if (data && extension.action === "installed") {
                if (!extensionList.includes(extension.addon)) {
                    extensionList.push(extension.addon);
                    this.setState({ extensionList: extensionList });
                }
            }
        };
        extensionListener(handleExtensionsChange);
        checkExtensionStat();
        let browser = detectOsSzn.Browser.detect().name;
        const os = detectOs();
        let browserHasExtensions = (!browser || browser === "Chrome" || browser === "Firefox") && (os === "mac" || os === "win");
        const url_string = window.location.href;
        const url = new URL(url_string);
        const param_value = url.searchParams.get("prohlizec");

        if (param_value) {
            browser = param_value;
            browserHasExtensions = true;
        }

        this.setState({
            browserHasExtensions: browserHasExtensions,
            browser: browser,
        });
    }

    render() {
        const { browserHasExtensions, extensionList } = this.state;
        return (
            <div>
                <MenuDesk />
                {browserHasExtensions !== undefined  && (
                    <HeaderDesk browserHasExtensions={browserHasExtensions} />
                )}
                {browserHasExtensions && (
                    <MainSection
                        extensionList={extensionList}
                        {...this.state}
                    />
                )}
                {browserHasExtensions && <FooterDesk />}
            </div>
        );
    }
}

export default IndexPage;
