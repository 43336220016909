import React, { useState } from 'react';
import * as Scroll from 'react-scroll';

import './down-arrow.scss';

import arrow from '../../../../static/img/button-arrow-down.svg';

const Arrow = (props) => {
  const scroller = Scroll.animateScroll;
  const slideDown = () => {
    scroller.scrollTo(680, {
      duration: 600,
      delay: 100,
      smooth: true,
    });
  };

  return (
    <div onClick={slideDown} className="arrow-down">
      <div className="arrow">
        <img src={arrow} alt="arrow" />
      </div>
    </div>
  );
};

class DownArrow extends React.PureComponent {
  render() {
    return (
      <Arrow os={this.props.os} />
    );
  }
}

export default DownArrow;
