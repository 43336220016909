import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import VisibilitySensor from 'react-visibility-sensor';

import './sections-desk.scss';

const Section = ({
  props,
  props: { sections, animationDelayCoord },
}) => {
  const [visible, setVisibility] = useState(false);

  const onChange = (isVisible) => {
    if (!visible) {
      setVisibility(isVisible);
    }
  };

  const FeatureBox = sections.map((data, index) => {
    const animationStyle = useSpring(
      {
        delay: 300 + index * 100,
        config: { mass: 10, tension: 550, friction: 250 },
        to: { opacity: visible ? 1 : 0 },
        from: { opacity: 0 },
      },
    );

    return (
      <animated.div className="feature-box" style={animationStyle} key={data.id}>
        <div className="imgWrap"><img alt="Text icon" src={data.image} /></div>
        <h3>{data.h}</h3>
        <p>{data.p}</p>
      </animated.div>
    );
  });

  return (
    <VisibilitySensor onChange={onChange} partialVisibility offset={animationDelayCoord}>
      <section className="feature-list">
        <div className="content-wrapper feature-list-content">
          {FeatureBox}
        </div>
      </section>
    </VisibilitySensor>
  );
};

class FeatureListSectionDesk extends React.PureComponent {
  render() {
    return (
      <Section props={this.props.sectionContent} />
    );
  }
}

export default FeatureListSectionDesk;
