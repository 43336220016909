import Section1Img from '../static/img/Seznam.svg';
import Section2Img from '../static/img/Email.svg';
import Section3Img from '../static/img/Vyhledavac.svg';
import Section4Img from '../static/img/Prevodnik.svg';
import Section5Img from '../static/img/Rychla_volba.svg';

const sectionData = [
    {
        id: "section1",
        sectionType: "MainFeatureSection",
        h: "Nejrychlejší cesta na Seznam",
        p:
            "Domovskou stránku Seznam.cz načte raz dva. Stačí kliknout na červené „eSko“. Navíc vás ochrání před podvodnými stránkami.",
        extensionName: "esko",
        extensionNameCZ: "Esko",
        animation: Section1Img,
        reverseContent: false,
        linkChrome:
            "https://chrome.google.com/webstore/detail/seznam-dopln%C4%9Bk-esko/olfeabkoenfaoljndfecamgilllcpiak",
        linkFirefox:
            "https://addons.mozilla.org/cs/firefox/addon/seznam-doplnek-esko/",
    },
    {
        id: "section2",
        sectionType: "MainFeatureSection",
        h: "Upozorní na e-maily",
        p:
            "Doplněk vás upozorní, když přijde nový e-mail. V pravém dolním rohu obrazovky vyskočí bublina s náhledem zprávy.",
        extensionName: "email",
        extensionNameCZ: "Email",
        animation: Section2Img,
        reverseContent: true,
        linkChrome:
            "https://chrome.google.com/webstore/detail/seznam-dopln%C4%9Bk-email/bgjpfhpjcgdppjbgnpnjllokbmcdllig",
        linkFirefox:
            "https://addons.mozilla.org/cs/firefox/addon/seznam-doplnek-email/",
    },
    {
        id: "section3",
        sectionType: "MainFeatureSection",
        h: "Vyhledávání na Seznamu",
        p: "Jedním kliknutím nastaví v adresním řádku vyhledávač od Seznamu.",
        extensionName: "search",
        extensionNameCZ: "Vyhledavani",
        animation: Section3Img,
        reverseContent: false,
        linkChrome:
            "https://chrome.google.com/webstore/detail/seznam-dopln%C4%9Bk-%E2%80%93-vyhled%C3%A1v/nofhadmjldbdiopcknbeklamhhggncfh",
        linkFirefox:
            "https://addons.mozilla.org/cs/firefox/addon/seznam-dopln%C4%9Bk-vyhled%C3%A1va%C4%8D/",
    },
]

export default sectionData;
