const OperatingSystem = function (name, version, humanName) {
  this.name = name;
  this.version = version;
  this.humanName = humanName;
};

OperatingSystem.prototype.compareVersions = function (refVersion) {
  return OperatingSystem.compareVersions(this.version, refVersion);
};

//
// Static
//

OperatingSystem._detected = {};
OperatingSystem.resolveNtHumanName = function (ntVersion) {
  if (OperatingSystem.compareVersions(ntVersion, "5.1") < 0) {
    return "Windows old";
  } else if (OperatingSystem.compareVersions(ntVersion, "6.0") < 0) {
    return "Windows XP";
  } else if (OperatingSystem.compareVersions(ntVersion, "6.1") < 0) {
    return "Windows Vista";
  } else if (OperatingSystem.compareVersions(ntVersion, "6.2") < 0) {
    return "Windows 7";
  } else if (OperatingSystem.compareVersions(ntVersion, "6.3") < 0) {
    return "Windows 8";
  } else if (OperatingSystem.compareVersions(ntVersion, "6.4") < 0) {
    return "Windows 8.1";
  } else if (OperatingSystem.compareVersions(ntVersion, "10.1") < 0) {
    return "Windows 10";
  } else {
    return "Windows new";
  }
};

OperatingSystem.MATCH_REGEXP = {};
OperatingSystem._osList = [
  { name: "Windows Phone", version: OperatingSystem.MATCH_REGEXP, humanName: "Windows Phone", regexp: /Windows ?Phone( ?(OS ?)?([0-9\._\-]+))?/i, notRegexp: /(Xbox|Windows CE|Windows Mobile)/i },
  { name: "Windows", version: "5.1", humanName: OperatingSystem.resolveNtHumanName, regexp: /Windows XP/i, notRegexp: /(Xbox|Phone|Windows CE|Windows Mobile)/i },
  { name: "Windows", version: OperatingSystem.MATCH_REGEXP, humanName: OperatingSystem.resolveNtHumanName, regexp: /Windows ?NT ?([0-9\._\-]+)?/i, notRegexp: /(Xbox|Phone|Windows CE|Windows Mobile)/i },
  { name: "Windows", version: "0", humanName: "Windows old", regexp: /Windows/i, notRegexp: /(Xbox|Phone|Windows CE|Windows Mobile)/i },
  { name: "iOS", version: OperatingSystem.MATCH_REGEXP, humanName: "iOS", regexp: /iPhone ?OS ?([0-9\._\-]+)/i },
  { name: "iOS", version: OperatingSystem.MATCH_REGEXP, humanName: "iOS", regexp: /(iPhone)|(iPod)|(iPad).*OS ?([0-9\._\-]+)/i },
  { name: "iOS", version: "0", humanName: "iOS", regexp: /(iPhone)|(iPod)|(iPad)/i },
  { name: "Mac OS", version: OperatingSystem.MATCH_REGEXP, humanName: "Mac OS X", regexp: /Intel ?Mac OS X ([0-9\._\-]+)/i },
  { name: "Mac OS", version: "0", humanName: "Mac OS X", regexp: /(Intel ?Mac)|(Macintosh)/i },
  { name: "Android", version: OperatingSystem.MATCH_REGEXP, humanName: "Android", regexp: /Android( ?[0-9\._\-]+)?/i },
  { name: "Solaris", version: "0", humanName: "Solaris", regexp: /(Solaris)|(SunOS)/i },
  { name: "BSD", version: "0", humanName: "BSD", regexp: /(BSD)/i },
  { name: "Linux", version: "0", humanName: "Linux", regexp: /(Linux)|(X11)/i }
];

OperatingSystem.detect = function (ua) {
  ua = ua || window.navigator.userAgent;

  if (!this._detected[ua]) {
    var os = this._detected[ua] = new OperatingSystem("Unknown", "0", "Unknown");

    for (var i = 0; i < this._osList.length; i++) {
      var osDefinition = this._osList[i];
      if (osDefinition.regexp.test(ua)) {
        if (!osDefinition.notRegexp || !osDefinition.notRegexp.test(ua)) {
          os.name = osDefinition.name;

          switch (osDefinition.version) {
            case OperatingSystem.MATCH_REGEXP:
              var matches = ua.match(osDefinition.regexp),
                version = (matches[matches.length - 1] || "0").trim();

              if (OperatingSystem.compareVersions(version, "0") > 0) {
                os.version = version;
              } else {
                os.version = "0";
              }
              break;

            default:
              os.version = osDefinition.version;
              break;
          }
          os.version = OperatingSystem.normalizeVersionString(os.version);

          if (typeof osDefinition.humanName === 'function') {
            os.humanName = osDefinition.humanName(os.version);
          } else {
            os.humanName = osDefinition.humanName;
          }
          break;
        }
      }
    }
  }

  return this._detected[ua];
};

OperatingSystem.normalizeVersionString = function (ver) {
  return ('' + ver).replace(/[\-_]/g, ".").replace(/[^0-9\.]/g, "");
};

OperatingSystem.compareVersions = function (ver1, ver2) {
  var arrVer1 = ver1 ? OperatingSystem.normalizeVersionString(ver1).split('.') : ['0'];
  var arrVer2 = ver2 ? OperatingSystem.normalizeVersionString(ver2).split('.') : ['0'];
  var len = Math.max(arrVer1.length, arrVer2.length);
  while (arrVer1.length < len) arrVer1.push('0');
  while (arrVer2.length < len) arrVer2.push('0');

  for (var i = 0; i < len; i++) {
    var diff = (parseInt(arrVer1[i], 10) || 0) - (parseInt(arrVer2[i], 10) || 0);
    if (diff !== 0) {
      if (diff < 0) return -1;
      return 1;
    }
  }
  return 0;
};


var Browser = function (name, version) {
  this.name = name;
  this.version = version;
};

Browser.prototype.compareVersions = function (refVersion) {
  return OperatingSystem.compareVersions(this.version, refVersion);
};


//
// Static
//

Browser._detected = {};
Browser._detectors = [];

Browser.addDetector = function (detector) {
  this._detectors.push(detector);
};

Browser.parseVersion = function (ua, start, afterVersion) {
  var end = ua.indexOf(afterVersion, start);
  if (end < 0) {
    end = ua.length;
  }
  return ua.substring(start, end);
};

Browser.standardDetector = function (regexps, name, ua) {
  for (var i = 0; i < regexps.length; i++) {
    var matches = ua.match(regexps[i]);
    if (matches && matches.length > 1) {
      return new Browser(name, matches[1]);
    }
  }
  return false;
};

Browser.versionTagDetector = function (regexps, versionRegexps, name, ua) {
  for (var i = 0; i < regexps.length; i++) {
    var matches = ua.match(regexps[i]);
    if (matches) {
      for (var v = 0; v < versionRegexps.length; v++) {
        var vMatches = ua.match(versionRegexps[v]);
        if (vMatches && vMatches.length > 1) {
          return new Browser(name, vMatches[1]);
        }
      }
    }
  }
  return false;
};

Browser.detect = function (ua) {
  ua = ua || window.navigator.userAgent;
  if (!this._detected[ua]) {
    try {
      for (var i = 0; i < this._detectors.length; i++) {
        var detected = this._detectors[i](ua);
        if (detected) {
          this._detected[ua] = detected;
          break;
        }
      }
    } catch (e) {
      console.error('[SBrowser promo] Error: ' + e.message, e);
    }
    this._detected[ua] = this._detected[ua] || new Browser('Unknown', '0', 'Tento prohlížeč je už zastaralý.', null);
  }
  return this._detected[ua];
};

// Detekce se provede v pořadí v jakém byly přidány jednotlivé detektory
// To je důležité, protože např. Chrome matchne detekci na Safari, ale naopak ne
Browser.addDetector(Browser.standardDetector.bind(Browser, [/Seznam\.cz\/(([\.\-]?[0-9]+)+)/i], 'Seznam.cz')); // Seznam.cz
Browser.addDetector(Browser.standardDetector.bind(Browser, [/SznProhlizec\/(([\.\-]?[0-9]+)+)/i], 'Seznam.cz')); // Seznam.cz
Browser.addDetector(Browser.versionTagDetector.bind(Browser, [/Opera\/((\.?[0-9]+)+)/i, /Presto\/((\.?[0-9]+)+)/i], [/version\/((\.?[0-9]+)+)/i], 'Opera')); // Opera (Presto)
Browser.addDetector(Browser.standardDetector.bind(Browser, [/Opera[\/ ]?((\.?[0-9]+)+)/i, /Presto[\/ ]?((\.?[0-9]+)+)/i], 'Opera')); // Opera (Presto)
Browser.addDetector(Browser.standardDetector.bind(Browser, [/OPR\/((\.?[0-9]+)+)/i], 'Opera')); // Opera (WebKit)
Browser.addDetector(Browser.standardDetector.bind(Browser, [/Edge\/((\.?[0-9]+)+)/i], 'Edge')); // MS Edge
Browser.addDetector(Browser.standardDetector.bind(Browser, [/[;\(] ?MSIE ?((\.?[0-9]+)+)/i], 'Internet Explorer')); // Internet Explorer
Browser.addDetector(Browser.versionTagDetector.bind(Browser, [/Trident\/((\.?[0-9]+)+)/i], [/; rv:((\.?[0-9]+)+)/i], 'Internet Explorer')); // Internet Explorer 11
Browser.addDetector(Browser.standardDetector.bind(Browser, [/Firefox[\/ ]?((\.?[0-9]+)+)/i], 'Firefox')); // Firefox
Browser.addDetector(Browser.standardDetector.bind(Browser, [/Chrome\/((\.?[0-9]+)+)/i, /Chromium\/((\.?[0-9]+)+)/i, /CriOS\/((\.?[0-9]+)+)/i], 'Chrome')); // Chrome
Browser.addDetector(Browser.standardDetector.bind(Browser, [/Android[ \/]?((\.?[0-9]+)+).*Mobile ?(:?Safari)?/i], 'Android')); // Android
Browser.addDetector(Browser.versionTagDetector.bind(Browser, [/Safari\/((\.?[0-9]+)+)/i], [/version\/((\.?[0-9]+)+)/i], 'Safari')); // Safari


const Client = function (operatingSystem, browser) {
  this.operatingSystem = operatingSystem;
  this.browser = browser;
};

Client.prototype.identification = function () {
  return this.operatingSystem.humanName + " (v" + this.operatingSystem.version + "), " + this.browser.name + " (v" + this.browser.version + ")";
};


//
// Static
//

Client._detected = {};
Client.detect = function (ua) {
  ua = ua || navigator.userAgent;
  if (!this._detected[ua]) {
    this._detected[ua] = new Client(OperatingSystem.detect(ua), Browser.detect(ua));
  }
  return this._detected[ua];
};

export default { OperatingSystem, Browser };
